import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import StoreContext from '~/context/StoreContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  ProductDescription,
  Price
} from '~/templates/ProductPage/styles'
  
const ProductForm = ({ product }) => {
  const {
  description,
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(() => {
        // this checks the currently selected variant for availability
        const result = variants.filter(
          variant => variant.shopifyId === productVariant.shopifyId
        )
        setAvailable(result[0].availableForSale)
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }	
  const handleQuantityMinus = ({ target }) => {
  if ( quantity >= 1 ) {
    setQuantity(quantity - 1)
    }
  }
  const handleQuantityPlus = ({ target }) => {
    setQuantity(quantity + 1)
  }	

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
  }

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)
  return (
    <>
    <Price>{price}</Price>
    <ProductDescription
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <br/>
      {options.map(({ id, name, values }, index) => (
      <div className={"optionwrap "+ name}>
      <span className="optionTitle">{name}</span>
        <React.Fragment key={id}>
            {values.map((value, Index) => (
            <label className={"productOption "+ value}>
              <input 
              type="radio" 
              name={name}
                value={value}
                key={`${name}-${value}`}
                disabled={checkDisabled(name, value)}
                onChange={event => handleOptionChange(index, event)}
                defaultChecked={(Index == 0) ? true : false}
              />
              {value}
              {(name == 'Color') ? <div class="colorBox"></div> : ''}
              </label>
            ))}
            
          <br />
        </React.Fragment>
        </div>
      ))}
      <label htmlFor="quantity" className="qty">QTY</label>
      <br/>
      <div className="qtyMinus">
      <FontAwesomeIcon icon={faMinus} onClick={handleQuantityMinus}/>
      </div>
      <input
        type="number"
        id="quantity"
        name="quantity"
        min="1"
        onChange={handleQuantityChange}
        value={quantity}
      />
      <div className="qtyPlus">
      <FontAwesomeIcon icon={faPlus} onClick={handleQuantityPlus}/>
      </div>
      <br />
      <button
        type="submit"
        disabled={!available || adding}
        onClick={handleAddToCart}
        className="addToCart"
      >
        Add to Cart
      </button>
      {!available && <p>This Product is out of Stock!</p>}
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
